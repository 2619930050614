<template>
  <div @click="setFocus">
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ">{{ $t("message.new_product") }}</span>
      <el-button
        type="success"
        size="small"
        class="mr-1"
        @click="submitForm()"
        >{{ $t("message.save") }}</el-button
      >
      <el-button
        type="warning"
        icon="el-icon-close"
        size="small"
        @click="close()"
        >{{ $t("message.close") }}</el-button
      >
    </header>

    <div class="body__modal">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        size="small"
        class="aticler_m stylekhanForm"
      >
        <el-card class="box-card mb-4">
          <el-row :gutter="20">
            <el-col :sm="12">
              <div class="d-flex">
                <div class="text_a mr-3">
                  {{ $t("message.purchasing_products") }} №
                  {{ $t("message.from") }}
                </div>
                <el-form-item :label="columns.date.title" prop="date">
                  <el-date-picker
                    v-model="form.date"
                    type="date"
                    :format="'yyyy-MM-dd'"
                    :value-format="'yyyy-MM-dd'"
                  />
                </el-form-item>
              </div>
            </el-col>
            <!-- end  col -->
            <el-col :sm="12" class="text-right">
              <span style="color: #6c757d !important">{{
                $t("message.username")
              }}</span>
              {{ username }}
            </el-col>
          </el-row>
          <!-- end el-row -->

          <el-row :gutter="40">
            <el-col :span="8">
              <el-form-item
                :label="columns.supplier_id.title"
                prop="supplier_id"
              >
                <el-row :gutter="5">
                  <el-col :span="22">
                    <el-form-item class="mb-0">
                      <suppliers
                        v-model="form.supplier_id"
                        :supplier_id="form.supplier_id"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="2" class="mb-0">
                    <div class="plusic" @click="drawer.provider.status = true">
                      <i class="el-icon-plus"/>
                    </div>
                  </el-col>
                </el-row>
              </el-form-item>
              <!-- end el-form-item-->

              <el-form-item
                :label="columns.supplier_contract_id.title"
                prop="supplier_contract_id"
              >
                <el-row :gutter="5">
                  <el-col :span="22">
                    <el-form-item class="mb-0">
                      <supplier-contracts
                        v-model="form.supplier_contract_id"
                        :supplier_contract_id="form.supplier_contract_id"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="2" class="mb-0">
                    <div class="plusic" @click="drawer.contract.status = true">
                      <i class="el-icon-plus"/>
                    </div>
                  </el-col>
                </el-row>
              </el-form-item>
              <!-- end el-form-item-->
            </el-col>
            <!-- end  col -->

            <el-col :span="8">
              <el-form-item
                :label="columns.delivery_deadline.title"
                prop="delivery_deadline"
              >
                <el-date-picker
                  v-model="form.delivery_deadline"
                  type="date"
                  :placeholder="columns.delivery_deadline.title"
                  :format="'yyyy-MM-dd'"
                  :value-format="'yyyy-MM-dd'"
                />
              </el-form-item>
              <!-- end el-form-item-->

              <el-form-item :label="columns.status_id.title" prop="status_id">
                <el-row :gutter="5">
                  <el-col :span="22">
                    <el-form-item class="mb-0">
                      <statuses
                        v-model="form.status_id"
                        :status_id="form.status_id"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="2" class="mb-0">
                    <div class="plusic" @click="drawer.status.status = true">
                      <i class="el-icon-plus"/>
                    </div>
                  </el-col>
                </el-row>
              </el-form-item>
              <!-- end el-form-item-->
            </el-col>
            <!-- end  col -->

            <el-col :span="8">
              <!-- end el-form-item-->
              <el-form-item
                :label="columns.description.title"
                prop="description"
              >
                <el-input
                  type="textarea"
                  v-model="form.description"
                  :placeholder="columns.description.title"
                />
              </el-form-item>
              <!-- end el-form-item-->
            </el-col>
            <!-- end  col -->
          </el-row>
          <!-- end el-row -->
        </el-card>

        <el-card class="box-card mb-4">
          <el-row :gutter="20" class="my-3">
            <el-col :span="18">
              <el-input
                v-model="qrCode"
                ref="qrInput"
                @input="getProductByQr"
              ></el-input>
            </el-col>
            <el-col :span="2">
              <el-button
                style="font-size: 14px !important; padding: 12px !important"
                @click="drawerProducts = true"
                >{{ $t("message.products") }}</el-button
              >
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-table border :data="form.items">
                <el-table-column label="#" width="30">
                  <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.name')">
                  <template slot-scope="scope">{{ scope.row.name }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.quantity')">
                  <template slot-scope="scope">
                    <el-input
                      min="1"
                      type="number"
                      v-model="scope.row.quantity"
                      size="mini"
                    />
                  </template>
                </el-table-column>
                <el-table-column :label="$t('message.box_quantity')">
                  <template slot-scope="scope">
                    <el-input
                      min="1"
                      type="number"
                      v-model="scope.row.box_quantity"
                      size="mini"
                    />
                  </template>
                </el-table-column>
                <el-table-column :label="$t('message.measurement')">
                  <template slot-scope="scope">
                    <el-select
                      v-model="scope.row.measurement_id"
                      filterable
                      clearable
                      size="mini"
                    >
                      <el-option
                        v-for="item in measurements"
                        :key="item.id + item.name"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('message.price2')">
                  <template slot-scope="scope">
                    <el-input
                      min="0"
                      type="number"
                      v-model="scope.row.price"
                      size="mini"
                    />
                  </template>
                </el-table-column>
                <el-table-column :label="$t('message.price2')">
                  <template slot-scope="scope">
                    {{ (scope.row.price * scope.row.quantity) | formatMoney }}
                  </template>
                </el-table-column>
<!--                <el-table-column :label="$t('message.currency')">
                  <template slot-scope="scope">
                    <currencies
                      v-model="scope.row.currency_id"
                      :currency_id="scope.row.currency_id"
                      @changed="updateCurrency(scope.row)"
                    ></currencies>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('message.rate')">
                  <template slot-scope="scope">
                    <el-input
                      min="0"
                      type="number"
                      v-model="scope.row.rate"
                      size="mini"
                    ></el-input>
                  </template>
                </el-table-column>-->
                <el-table-column :label="$t('message.actions')">
                  <template slot-scope="scope">
                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click="removeProduct(scope.row)"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </el-col>

            <!-- end  col -->
            <!-- <el-col :sm="12">
              <el-form-item class="mb-0">
                <el-select
                  :placeholder="$t('message.products')"
                  filterable
                  clearable
                  @input="selectProduct"
                  v-model="selectedProduct"
                >
                  <el-option
                    v-for="item in products"
                    :key="item.id + item.name"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
            <!-- end  col -->
            <el-col :sm="12">
              <span class="font-weight-bold"
                >{{ $t("message.total") }}: {{ total | formatMoney }} UZS</span
              >
            </el-col>
            <!-- end  col -->
          </el-row>
        </el-card>
      </el-form>
    </div>

    <el-drawer
      size="50%"
      :wrapperClosable="false"
      :append-to-body="true"
      :visible.sync="drawer.status.status"
      :with-header="false"
      :ref="drawer.status.name"
    >
      <Status
        :drawer-name="drawer.status.name"
        @onStatusCreated="setCreatedStatus"
      />
    </el-drawer>

    <el-drawer
      size="70%"
      :wrapperClosable="false"
      :append-to-body="true"
      :visible.sync="drawer.provider.status"
      :with-header="false"
      :ref="drawer.provider.name"
    >
      <Provider
        :drawer-name="drawer.provider.name"
        @onSupplierCreated="setCreatedSupplier"
      />
    </el-drawer>

    <el-drawer
      size="70%"
      :wrapperClosable="false"
      :append-to-body="true"
      :visible.sync="drawer.contract.status"
      :with-header="false"
      :ref="drawer.contract.name"
    >
      <Contract
        :drawer-name="drawer.contract.name"
        @onContractCreated="setCreatedContract"
      />
    </el-drawer>

    <el-drawer
      class="popups_title"
      :wrapperClosable="false"
      :append-to-body="true"
      :visible.sync="drawerProducts"
      direction="rtl"
      size="85%"
      :ref="drawer.product.name"
      @opened="drawerOpened(drawer.product.component)"
      @closed="drawerClosed(drawer.product.component)"
    >
      <CreateProducts
        :ref="drawer.product.component"
        :driver="drawerProducts"
        @items="itemsChanged"
        @c-close="closeDrawerProducts"
      />
    </el-drawer>
  </div>
</template>
<script>
import Status from "./status-modal";
import Provider from "./provider-modal";
import Contract from "./contract-modal";
import CreateProducts from "@/views/scheduler/create-product";

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import drawerChild from "@/utils/mixins/drawer-child";
import form from "@/utils/mixins/form";

import currencies from "@/components/inventory-select/currencies";
import statuses from "@/components/inventory-select/statuses";
import supplierContracts from "@/components/inventory-select/supplierContracts";
import suppliers from "@/components/inventory-select/suppliers";

export default {
  components: {
    Status,
    Provider,
    Contract,
    currencies,
    statuses,
    supplierContracts,
    suppliers,
    CreateProducts,
  },
  mixins: [form, drawer, drawerChild],
  data() {
    return {
      selectedProduct: null,
      drawerProducts: false,
      qrCode: null,
      drawer: {
        status: {
          name: "status",
          status: false,
        },
        provider: {
          name: "provider",
          status: false,
        },
        contract: {
          name: "contract",
          status: false,
        },
        product: {
          name: "product",
          status: false,
          component: "productDrawer",
        },
      },
    };
  },
  async mounted() {
    this.$refs.qrInput.focus();
    if (this.products && this.products.length === 0) await this.loadProducts();
    if (this.measurements && this.measurements.length === 0)
      await this.loadMeasurements();
  },
  computed: {
    ...mapGetters({
      username: "auth/name",
      model: "procurements/model",
      rules: "procurements/rules",
      columns: "procurements/columns",
      suppliers: "suppliers/inventory",
      statuses: "statuses/inventory",
      supplier_contracts: "supplierContracts/inventory",
      products: "products/inventory",
      currencies: "currencies/inventory",
      measurements: "measurements/inventory",
    }),
    total() {
      if (this.form.items.length != 0 && this.form.items) {
        return _.sumBy(this.form.items, function (o) {
          let rate = o.currency
            ? o.currency.reverse
              ? _.round(1 / o.rate, 8)
              : o.rate
            : 1;
          return _.round(+o.price * +o.quantity * +rate, 2);
        });
      }
      return 0;
    },
  },
  methods: {
    ...mapActions({
      save: "procurements/store",
      update: "procurements/update",
      editModel: "procurements/show",
      empty: "procurements/empty",
      loadProducts: "products/inventory",
      searchByCode: "products/searchByCode",
      loadMeasurements: "measurements/inventory",
    }),
    getName(id, from) {
      const data = this[from].find((item) => {
        return item.id == id;
      });
      return data ? data.name : "";
    },
    setFocus($event) {
      if (
        $event.srcElement.localName == "div" ||
        $event.srcElement.localName == "form" ||
        $event.srcElement.localName == "span"
      ) {
        this.$refs.qrInput.focus();
      }
    },
    getProductByQr: _.debounce(function (val) {
      if (val != "" && Number(val))
        this.searchByCode(val)
          .then((res) => {
            if (res.data.product != null) this.itemChanged(res.data.product);
            else
              this.$notify({
                title: this.$t("message.validation"),
                type: "error",
                position: "bottom right",
                message: "product not found",
              });
          })
          .finally((fin) => {
            this.qrCode = null;
          });
      else this.qrCode = null;
    }, 500),

    closeDrawerProducts(obj) {
      if (obj.drawer) {
        this[obj.drawer] = false;
      }
    },
    itemsChanged(val) {
      val.forEach((element) => {
        this.form.items.push({
          name: element.name,
          product_id: element.id,
          quantity: null,
          box_quantity: element.quantity,
          measurement_id: element.measurement_id,
          price: element.price,
          rate: this.getRate(element.currency_id),
          currency_id: element.currency_id,
          currency: element.currency,
        });
      });
    },
    itemChanged(element) {
      if (!this.form.items.find((product) => product.name === element.name)) {
        this.form.items.push({
          name: element.name,
          product_id: element.id,
          quantity: null,
          box_quantity: element.quantity,
          measurement_id: element.measurement_id,
          price: element.price,
          rate: this.getRate(element.currency_id),
          currency_id: element.currency_id,
          currency: element.currency,
        });
      }
    },
    getRate(id) {
      const data = this.currencies.find((item) => {
        return item.id == id;
      });
      return data ? data.rate : "";
    },
    selectProduct(id) {
      const product = this.products.find((product) => product.id === id);
      this.form.items.push({
        name: product.name,
        product_id: product.id,
        quantity: 0,
        box_quantity: product.quantity,
        measurement_id: product.measurement_id,
        price: product.price,
        rate: this.getRate(product.currency_id),
        currency_id: product.currency_id,
        currency: product.currency,
      });
      this.selectedProduct = null;
    },
    removeProduct(item) {
      const index = this.form.items.indexOf(item);
      this.form.items.splice(index, 1);
    },
    submitForm(resetForm = false) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          // this.form.items.forEach(element => {
          //   element.rate = this.getRate(element.currency_id);
          // });
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$notify({
                title: "Успешно",
                type: "success",
                offset: 130,
                message: res.message,
              });
            })
            .catch((err) => {
              this.$notify({
                title: "Ошибка",
                type: "error",
                offset: 130,
                message: err.error.message,
              });
              this.loadingButton = false;
            })
            .finally(() => {
              this.$refs["form"].resetFields();
              this.empty();
            });
        }
      });
    },
    updateCurrency(item) {
      if (item) {
        const currency = this.currencies.find((c) => c.id == item.currency_id);
        if (currency) {
          item.currency = currency;
          item.rate = currency.reverse ? currency.reversed_rate : currency.rate;
        }
      }
    },
    setCreatedStatus(statusID) {
      this.form.status_id = statusID;
    },
    setCreatedSupplier(supplierID) {
      this.form.supplier_id = supplierID;
    },
    setCreatedContract(contractID) {
      this.form.supplier_contract_id = contractID;
    },
  },
};
</script>
