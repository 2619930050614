export const filter = {
    id: "",
    search: "",
    name: "",
    slug: "",
    parent_id: "",
    enabled: "",
    created_at: "",
    updated_at: "",
    start_date: '',
    end_date: '',
};
